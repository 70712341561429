<template>
    <div>
        <div
            v-if="isPaymentLoading"
            class="credit-overlay"
        >
            <CSpinner color="primary" style="width: 3rem; height: 3rem" />
        </div>

        <CToaster :autohide="3000">
            <template v-for="info in infoList">
                <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
                    {{ info.message }}.
                </CToast>
            </template>
        </CToaster>

        <CContainer>
            <CCard class="header-credit">
                <CCardBody>
                    <CRow style="justifyContent: space-between">
                        <CCol sm="auto" md="auto" lg="auto">
                            <CRow >
                                <CCardText class="available-credit">Available AI Credit:</CCardText>
                                <CCardTitle class="credit-value">{{ availableCredit }}</CCardTitle>
                            </CRow>
                        </CCol>
                        <CCol sm="auto" md="auto" lg="auto">
                            <!-- <CButton
                                class="top-up-button"
                                @click="openTopupCreditPopup()"
                            >
                                {{'+ Top Up Credit'}}
                            </CButton> -->
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CContainer>

        <CContainer class="container-credit">
            <h1 class="transaction-history">Transaction History</h1>
            <CCard>
                <CCardBody>
                    <CDataTable
                        :items="creditFieldItems"
                        :fields="creditFieldList"
                        column-filter
                        table-filter
                        items-per-page-select
                        :items-per-page="10"
                        hover
                        sorter
                        :sorterValue="{column: 'createdAt', asc: false}"
                        pagination
                        :loading="isLoadingCreditData"
                        caption="Credit Table"
                    >
                        <template #status="{item}">
                            <td>
                                <h5>
                                    <CBadge :color="getBadge(item.status)">
                                        {{item.status}}
                                    </CBadge>
                                </h5>
                            </td>
                        </template>
                        <template #amount="{item}">
                            <td >
                                <p v-if="item.paymentType == 'deduct'" style=" color:red ">
                                    - {{parseFloat(item.amount).toFixed(2)}} USD
                                </p>
                                <p v-else>
                                    + {{parseFloat(item.amount).toFixed(2)}} USD
                                </p>
                            </td>
                        </template>
                        <template #aiCredit="{item}">
                            <td v-if="item.aiCredit !== undefined">
                                <p v-if="item.paymentType == 'deduct'" style=" color:red ">
                                    {{parseFloat(item.aiCredit).toFixed(2)}}
                                </p>
                                <p v-else>
                                    + {{parseFloat(item.aiCredit).toFixed(2)}}
                                </p>
                            </td>
                            <td v-else>
                                <p>
                                    -
                                </p>
                            </td>
                        </template>
                        <template #createdAt="{item}">
                            <td>
                                <p>
                                    {{ formatDate(item.createdAt) }}
                                </p>
                            </td>
                        </template>
                    </CDataTable>
                </CCardBody>
            </CCard>
        </CContainer>

        <div>
            <CModal title="Top Up Credit" :show.sync="topupCreditPopup">
                <div>
                    <CForm>
                        <CInput
                            label="Top Up Amount"
                            placeholder="0.00 USD"
                            v-model="amountValue"
                        />
                    </CForm>
                </div>
                <div slot="footer" class="w-100">
                <CButton
                    class="top-up-button float-right"
                    @click="confirmTopupCredit()"
                    :disabled="amountValue == ''"
                >
                    Top Up
                </CButton>
            </div>
            </CModal>
        </div>

        <div>
            <CModal title="Payment" :show.sync="paymentPopup" size="lg">
            <div id="link-authentication-element"></div>
                <div id="payment-element"></div>
                <div slot="footer" class="w-100">
                <CButton
                class="ml-1 mr-1 float-right"
                color="primary"
                @click="handleSubmitTopup"
                >
                Continue
                </CButton>
                <CButton
                class="ml-1 mr-1 float-right"
                color="secondary"
                @click="paymentPopup = false"
                >
                Cancel
                </CButton>
            </div>
            </CModal>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import ProfileApi from '@/lib/profileApi'
let userData = window.localStorage.getItem('user');
let userDataJson = JSON.parse(userData)
let apiUrl = process.env.VUE_APP_API_URL;
const stripeKey = process.env.VUE_APP_STRIPE_KEY;

const creditFieldList = [
  { key: "transactionId", label: "Transaction ID" },
  { key: "amount", label: "Amount" },
  { key: "aiCredit", label: "AI Credit" },
  { key: "status", label: "Status" },
  { key: "createdAt", label: "Created Date" },
//   {
//     key: "show_details",
//     label: "",
//     _style: "width:1%",
//     sorter: false,
//     filter: false,
//   },
];

export default {
  name: 'AiCredit',
  data: function () {
    return {
        availableCredit: 0,
        creditFieldList,
        creditFieldItems: [],
        isActiveSubscribe: false,
        isLoadingCreditData: false,
        topupCreditPopup: false,
        amountValue: '',
        stripeKey,
        paymentPopup: false,
        isPaymentLoading: false,
        emailAddress: "",
        elements: null,
        infoList: [],
        message: "",
        getUserApi: new ProfileApi(),
    }
  },
  computed: {
    sortedPlans() {
        // Use slice() to create a copy of the array before sorting
        return this.creditFieldItems.slice().sort((a, b) => a.amount - b.createdAt);
    }
  },
  methods: {
    getBadge (status) {
        switch (status) {
            case 'success': return 'success'
            case 'failed': return 'danger'
            case 'pending': return 'warning'
            default: 'primary'
        }
    },

    formatDate(date) {
        return moment(date).format('DD/MM/YYYY hh:mm A');
    },

    openTopupCreditPopup() {
        if (this.isActiveSubscribe === false){
            this.toast(
            "Error",
            "Please subscribe to a plan first",
            "danger"
            );
        } else {
            this.topupCreditPopup = true;
        }
    },

    confirmTopupCredit() {
         // TODO: will add minimim msg in backend
        if (this.amountValue < 200) {
            this.toast(
            "Error",
            "The minimum amount is 200 USD",
            "danger"
            );
        } else {
            this.initializePayment()
        }
    },

    async initializePayment() {
        let res;
        try {
            res = await this.callCreditIntentAPI(this.amountValue);
            this.paymentPopup = true
        } catch (res) {
            this.toast(
            "Error",
            res.data.message,
            "danger"
            );
        }
        const { clientSecret } = await res;

        const appearance = {
            theme: "stripe",
        };

        try {
            let pkStripe = this.stripeKey;
            this.stripe = await loadStripe(pkStripe);
            console.log("Stripe", this.stripe);
            this.elements = this.stripe.elements({ appearance, clientSecret });
            // Now you can use this.elements for further interactions with Stripe Elements
            } catch (error) {
            console.error("Error initializing Stripe:", error);
        }
        console.log("Elements :", this.elements)

        const linkAuthenticationElement = this.elements.create("linkAuthentication");
        console.log(linkAuthenticationElement)
        linkAuthenticationElement.mount("#link-authentication-element");


        linkAuthenticationElement.on("change", (event) => {
            this.emailAddress = event.value.email;
        });


        const paymentElementOptions = {
            layout: "tabs",
        };


        const paymentElement = this.elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");

    },

    callCreditIntentAPI(amount) {
        var url = apiUrl+'credits/create-credit-intent';
        let data = {
            userId: userDataJson.id,
            amount: amount,
        }
        console.log('callCreditIntentAPI', data)
        return api.call('post', url, data).then((response) => {
            console.log(response.data);
            return response.data
        });
    },

    async handleSubmitTopup() {
        this.isPaymentLoading = true;
        this.paymentPopup = false;
        this.topupCreditPopup = false;

        const { data } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
            return_url: `https://fsrapp.firesafetyreview.com/#/ai-credit`,
            receipt_email: this.emailAddress,
            },
            redirect: "if_required",
        });

        await this.fetchCreditData();
        await this.checkAvailableCredit();

        if (data && (data.type === "card_error" || data.type === "validation_error")) {
            console.log("data error :", data)
            this.toast(
            "Failed",
            data.message,
            "danger"
            );
        } else {
            console.log("data success :", data)
            this.toast(
            "Success",
            "Payment succeeded!",
            "success"
            );
        }

        this.isPaymentLoading = false;
    },

    checkActiveUserSubs() {
        let url = apiUrl+'usersubs';
        let status = 'active';
        let userId = userDataJson.id;

        return api.call('get', url+'?status='+status+'&userId='+userId)
            .then((response) => {
                    if(response.data.totalResults > 0) {
                        this.isActiveSubscribe = true
                    }
            });
    },

    checkAvailableCredit() {
        let userId = userDataJson.id;
        return this.getUserApi.getProfileById(userId).then(() => {
            this.availableCredit = userDataJson.creditBalance
        });
    },

    toast(header, message, color) {
        var self = this;
        self.infoList.push({
            header: header,
            message: message,
            color: color,
        });
    },

    fetchCreditData() {
        this.isLoadingCreditData = true;
        let url = apiUrl+'/credits/';
        return api.call('get', url + userDataJson.id)
            .then((response) => {
                this.creditFieldItems = response.data.userCreditTransactions.map((item, id) => { return {...item, id}});
                return response.data
            })
            .catch(({ data }) => {
                this.toast(
                    "Error",
                    data.message,
                    "danger"
                )
            })
            .finally(() => {
                this.isLoadingCreditData = false;
            })
    },

    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.toast(
            "Success",
            "Payment succeeded!",
            "success"
          );
          break;
        case "processing":
          this.toast(
            "Info",
            "Your payment is processing.",
            "info"
          );
          break;
        case "requires_payment_method":
          this.toast(
            "Error",
            "Your payment was not successful, please try again.",
            "danger"
          );
          break;
        default:
          this.toast(
            "Error",
            "Something went wrong.",
            "danger"
          );
          break;
      }
    },
  },
  mounted() {
    this.checkActiveUserSubs()
    this.fetchCreditData()
    this.checkAvailableCredit()
  }
}
</script>

<style>
.available-credit {
    color: #8C8C8C;
    font-size: 16px;
    line-height: 45px;
    margin-right: 8px;
    margin-bottom: 0px;
    letter-spacing: 0.2px;
}
.credit-value {
    color: #434343;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin: 0px;
    line-height: 40px;
}
.top-up-button{
    display: flex;
    padding: 8px 15px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: #FFFFFF;
    background-color: #0A1439;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border-radius: 40px;
    /* line-height: 22px; */
}
.top-up-button:hover{
    cursor: pointer;
    color: #FFFFFF;
}
.header-credit {
    max-width: 59rem;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
}
.container-credit{
    max-width: 59rem;
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    padding-top: 40px;
}
.transaction-history{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding-bottom: 20px;
}
.header-filter {
    color: #8C8C8C;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.credit-overlay {
  position: fixed;
  left: 110px;
  z-index: 2;

  width: 100%;
  height: 100%;
  /* background: rgb(0 0 0 / 80%); */

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
